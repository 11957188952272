@use "../style/normalize.scss" as *;
@use "../style/variables.scss" as *;
@use "./mixin.scss" as mixin;

.app {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Poppins", serif;
  margin: 0 auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

// блок прокрутки
.body-no-scroll {
  overflow: hidden;
}

.spinner {
  @include mixin.spinner;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.flag {
  max-width: 25px;
  max-height: 20px;
  margin-top: 6px;
}

.aboutUs {
  display: flex;
  flex-direction: column;
  max-width: 820px;
  // margin-bottom: 160px;
  text-align: justify;
  overflow: hidden;
  transition: height 0.3s ease;
  user-select: none;
  gap: 18px;

  &__container1 {
    border: 1px solid $theme-color;
    padding: 10px 15px 15px 15px;
    transition: max-height 0.7s ease-in-out;
    border-radius: 10px;
    max-height: 55px;
    overflow: hidden;
    cursor: pointer;
    &.open {
      max-height: 5000px;
    }

    .aboutUs__title {
      @media (max-width: 525px) {
        font-size: 20px;
      }
      @media (max-width: 455px) {
        margin: 6px 0 10px 0;
        font-size: 15px;
      }
    }
  }

  &__container2 {
    counter-reset: section; /* Ініціалізація лічильника */
    border: 1px solid $theme-color;
    padding: 15px;
    transition: max-height 0.7s ease-in-out;
    border-radius: 8px;
    max-height: 55px;
    overflow: hidden;
    line-height: 1;
    cursor: pointer;
    @media (max-width: 650px) {
      padding: 6px;
    }
    .aboutUs__title {
      @media (max-width: 650px) {
        font-size: 20px;
      }
      @media (max-width: 455px) {
        margin: 9px 0;
        font-size: 15px;
      }
    }
  }

  &__title,
  &__title2 {
    font-size: 25px;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    user-select: none;
    backface-visibility: hidden;
    @media (max-width: 525px) {
      text-align: center;
    }
  }

  &__title2 {
    font-size: 20px;
    font-weight: bold;
    width: 100%;
  }

  &__title3 {
    font-size: 16px;
    font-weight: bold;
  }

  &__subTitle {
    text-align: center;
    font-weight: bold;
    color: $theme-color;
    width: 100%;
  }

  &__center {
    text-align: center;
  }

  &__p {
    font-size: 16px;
    line-height: 1.6;
    margin: 8px 0;
    text-align: justify; // не убирай це
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: 575px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__ul {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-bottom: 15px;
    counter-reset: section; /* Ініціалізація лічильника для кожного списку */
  }

  &__li--question {
    counter-increment: section; /* Збільшення лічильника для кожного питання */
    width: 100%;
    text-align: center;
    margin-top: 15px;
    list-style-type: none; // Видаляємо маркери для питань
    color: $theme-color; // Задаємо колір тексту для питань
    font-weight: bold; // Робимо текст питань жирним
  }

  &__li,
  &__li--answer {
    list-style-type: disc; // Відновлюємо маркери для відповідей
    text-align: justify;
    // padding-left: 9px; // Задаємо відступ зліва
    // margin: 3px 0;
    &::marker {
      content: "\2713"; // Задаємо власний маркер
      color: $theme-color; // Колір маркера
      font-weight: bold; // Жирність маркера
    }
  }

  &__linkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover .aboutUs__linkImg {
      transform: scale(1.05);
    }
  }

  &__linkImg {
    height: 50px;
    width: auto;
    transition: transform 0.3s ease;
  }

  &__linkText {
    margin: 13px 0;
    font-size: 15px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  }

  &__linkText2 {
    font-size: 14px;
    text-decoration: underline;
  }
}
