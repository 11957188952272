@use "./variables.scss" as *;

body {
  overflow-y: scroll; // Завжди показуємо вертикальну смугу прокрутки

  &::-webkit-scrollbar {
    width: 7px; // Початкова ширина смуги прокрутки
    background-color: rgba(0, 0, 0, 0.1); // Колір фону смуги прокрутки
    transition: width 0.3s ease; // Плавний перехід ширини
  }

  &::-webkit-scrollbar-thumb {
    background-color: $theme-color; // Колір "великого пальця"
    border-radius: 5px; // Закруглені кути
    &:hover {
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #44545b; // Колір треку
  }
}
