@use "../../../style/variables.scss" as *;
@use "../../../style/mixin.scss" as mixin;

.courses {
  display: flex;
  align-items: center;
  flex-direction: column;
  // max-width: 1300px;
  width: 100%;
  padding: 0 30px 50px 30px;
  gap: 10px;
  @media (max-width: 950px) {
    padding: 15px;
    margin-bottom: 20px;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;

    align-items: center;
    max-width: 1500px;
    width: 100%;
    min-height: 250px;
    // padding: 0 15px;
    background: rgba(white, 0.8);
    transition: transform 0.6s ease-out; // Додайте це для плавної анімації
    transform-style: preserve-3d; // Забезпечує глибину анімації
    gap: 10px;
    // @media (max-width: 1345px) {
    //   flex-direction: column;
    //   align-items: center;
    // }
    @media (max-width: 440px) {
      padding: 0;
    }
    &--flip {
      transform: perspective(600px) rotateY(-180deg);
    }
    &--flipBack {
      transform: perspective(600px) rotateY(0);
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    @media (max-width: 1460px) {
      max-width: 1000px;
    }
  }

  &__wrap {
    display: flex;
    width: 100%;
    margin-top: 10px;
    justify-content: flex-start;
    @media (max-width: 1460px) {
      max-width: 1000px;
    }
    @media (max-width: 1000px) {
      justify-content: center;
    }
  }

  &__title {
    font-size: 35px;
    // width: 100%;
    text-transform: uppercase;
    margin: 10px 0 0 5%;

    user-select: none;
    backface-visibility: hidden;
    @media (max-width: 767px) {
      text-align: center;
      font-size: 30px;
      margin: 0 auto;
    }
  }

  &__subTitle {
    text-decoration: underline;
    margin-bottom: 10px;
    font-style: italic;
  }

  &__card-ul {
    display: flex;
    flex-direction: column;
    @media (max-width: 525px) {
      gap: 15px;
    }
  }

  &__text-li {
    padding: 0 0 5px 5px;
    &::marker {
      content: "\2713";
      color: $theme-color;
      font-weight: bold;
    }
  }

  &__card-li {
    position: relative;
    display: inline-block;
    // font-size: 35px;
    list-style: none;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: transparent;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.3s ease-out, background-color 0.3s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
      background-color: white;
    }
  }

  &__card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 315px;

    height: 110px;
    font-size: 20px;
    padding: 29px;
    margin: 10px;
    border-radius: 10px;
    transform: translate(-1%, -1%);
    backface-visibility: hidden;
    transition: all 0.3s ease; // Додали анімацію для плавних змін розмірів та видимості
    cursor: pointer;
    &:hover {
      .courses__card--front {
        transform: perspective(600px) rotateX(-180deg);
      }
      .courses__card--back {
        transform: perspective(600px) rotateX(0);
      }
    }
    @media (max-width: 1460px) {
      max-width: 450px;
    }
    @media (max-width: 900px) {
      width: 100%;
      max-width: unset;
    }
  }

  &__card-title {
    @include mixin.articleTitle;
    color: white;
    letter-spacing: 0px;
  }

  &__card--front,
  &__card--back {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; // For Safari
    transition: transform 0.5s ease;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  &__card--front {
    color: white;
    font-size: 20px;
    transform: perspective(600px) rotateX(0);
    background: linear-gradient(
      to top right,
      $theme-color2,
      lighten($theme-color, 3%)
    );
    // background: linear-gradient(
    //   $theme-color3,
    //   lighten($theme-color3, 12%),
    //   $theme-color3
    // );
    // img {
    //   width: 100%;
    //   height: auto;
    // }
  }

  &__card--back {
    transform: perspective(600px) rotateX(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    user-select: none;
    background: darken($theme-color2, 6%);
    gap: 20px;
  }

  &__btn {
    @include mixin.button;
    margin-top: 15px;
  }

  &__cross {
    @include mixin.cross;
    color: white;
    top: 5px;
    right: 12px;
  }
}

.proContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 25px;
  margin: 0 5px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: $theme-color;
  color: white;
  text-align: center;

  &__title {
    margin: 10px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__text {
    font-size: 20px;
    letter-spacing: 2px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &__img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 7px;
  }
}

@keyframes flip {
  from {
    transform: perspective(600px) rotateY(0);
  }
  to {
    transform: perspective(600px) rotateY(-180deg);
  }
}
