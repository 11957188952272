@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Затемнений фон
    z-index: 1000; // Високий z-index для перекриття іншого контенту
  }

  &__message {
    color: white;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    &--red {
      color: red;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__btn {
    margin: 10px 0;
    @include mixin.button;
  }

  &.small {
    .spinner-image {
      width: 50px;
      height: 50px;
    }
  }

  &.medium {
    .spinner-image {
      width: 100px;
      height: 100px;
    }
  }

  &.large {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    .spinner-image {
      width: 200px;
      height: 200px;
    }
  }
}
