@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chewy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@700&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap&subset=cyrillic");
// @import url("https://fonts.googleapis.com/css2?family=Schoolbell&display=swap&subset=cyrillic");

// @import url("https://fonts.googleapis.com/css2?family=Ruslan+Display&display=swap&subset=cyrillic");
// @import url("https://fonts.googleapis.com/css2?family=Neucha&display=swap&subset=cyrillic");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap&subset=cyrillic");

// $theme-color: #8d0515;
// $theme-color: #8585cc;
$theme-color: #009fe3;
$theme-color2: darken($theme-color, 6%);
$theme-color3: #ff6058;
$opacity: 0.96;

// $gray-light: #DCDCDC;
// $gray-medium: #A9A9A9;
// $gray-dark: #696969;

// $theme-color: #ff6f61;
// $theme-color: rgb(112, 112, 226);

// $theme-color: #ff6058;
// $theme-color2: #ff2b20;

// $theme-color: #006400;
// $theme-color: #228B22;
// $theme-color: #00FF00;
// $theme-color: #808000;
// $theme-color: #00FF00;
// $theme-color: #8F9779;
// $theme-color: olive;
// $theme-color: #6767a5;

// Теплі пастельні відтінки:

// Пастельний рожевий: #ffb6c1
// Пастельний персиковий: #ffdab9
// Світлий лавандовий: #e6e6fa
// Пастельний жовтий: #fffacd
// Світло-помаранчевий: #ffd700
// Зелені відтінки:

// М'ятний: #98ff98
// Пастельний зелений: #b0e57c
// Лаймовий: #00ff00
// Персиковий зелений: #aaffc3
// Зелений чай: #d0f0c0
