@use "../../../style/variables.scss" as *;
@use "../../../style/mixin.scss" as mixin;

.popup {
  position: fixed;
  bottom: 70px;
  right: 5px;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    height: 130px;
    padding: 15px 27px 50px 7px;

    background-image: url("../../../lib/logo-icons/messageBox2.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1; // Значення більше, ніж у .popup__wrap
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05) rotate(2deg); // Збільшення масштабу та легке обертання
    }
  }

  &__text {
    font-size: 11px;
    color: #333;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: pre-wrap;
    word-break: break-all;

    span {
      opacity: 0;
      display: inline-block;
    }
  }

  &__wrap {
    position: absolute;
    top: -15px;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba($theme-color, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 2; // Менше значення для того, щоб опинитись позаду .popup__content
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    &:hover {
      background-color: rgba($theme-color, 0.3); // Зміна кольору фону
      transform: scale(1.1); // Збільшення масштабу
      .popup__close-btn {
        color: #000;
      }
    }
  }

  &__close-btn {
    background: transparent;
    border: none;
    color: #666;
    font-size: 30px;
    cursor: pointer;
  }
}
