@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.burger {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  width: 30px;
  margin: 0 6px;
  cursor: pointer;
  @media (max-width: 930px) {
    display: flex;
  }

  &__line {
    background-color: white;
    height: 3px;
    width: 100%;
    transition: transform 0.3s, opacity 0.3s;
  }
  &--open {
    .burger__line:nth-child(1) {
      transform: rotate(-45deg) translate(-12.5px, 6px);
    }
    .burger__line:nth-child(2) {
      opacity: 0;
    }
    .burger__line:nth-child(3) {
      transform: rotate(45deg) translate(-12.5px, -6px);
    }
  }

  &__container {
    position: absolute;
    top: 55px;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    align-items: center;
    background-color: rgba(black, 0.7);
    padding: 10px 10px;
    width: 100%;
    overflow: auto;
    transform: translateX(100%);
    height: 0;
    transition: transform 0.3s ease, height 0.3s ease, top 0.2s ease,
      opacity 0.3s ease;
    gap: 10px;
    &--open {
      transform: translateX(0);
      height: 100vh;
    }
    &--short {
      top: 80px;
    }
  }

  &__menu-content {
    overflow: hidden; // Запобігає виходу контенту за межі
    max-height: 0; // Початковий стан
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease; // Анімація
    &.open {
      max-height: var(--max-height); // Встановлюється через JavaScript
      opacity: 1;
    }
  }

  &__menu-item {
    display: flex;
    flex-direction: column;
    text-align: flex-start;
    padding: 1px;
    box-shadow: inset 0 0 5px rgba(black, 0.7);
    width: 100%;
    border-radius: 7px;
    background-color: lighten($theme-color, 10%);
    color: white;
  }

  &__menu-title {
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    border-radius: 7px;
    background: linear-gradient(
      to top,
      $theme-color2,
      lighten($theme-color, 10%),
      $theme-color2
    );
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
  }

  &__menu-element {
    background-color: white;
    font-family: "Roboto", sans-serif;
    color: darken($theme-color, 40%);
    padding: 10px 15px;
    margin: 5px;
    font-weight: bold;
    border-radius: 5px;
    text-shadow: 0 0 1px rgba(black, 0.4);
    box-shadow: inset 0 0 5px rgba(black, 0.7);
    transition: background-color 0.3s, transform 0.3s;
  }

  &__partner {
    display: flex;
    align-items: center;
    cursor: pointer;
    // padding: 10px;
    color: black;
    background-color: white;
    border-radius: 7px;
    // box-shadow: inset 0 0 5px rgba(black, 0.7);
    transition: background-color 0.3s;

    &-img {
      width: 50px;
      height: auto;
      margin-right: 10px;
    }

    &-text {
      color: black;
      font-weight: bold;
    }
  }
}

.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 15px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
  transition: transform 0.3s;

  &--turn {
    transform: rotate(180deg);
  }
}
